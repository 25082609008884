import React, { useState } from 'react';
import Head from 'next/head';
import { Accordion, Tabs, Text } from '@belong/ui';
import { StringComponent } from 'src/features/faqs/components/string/String';
import { FAQS_STRINGS } from 'src/features/faqs/faqs.strings';

const faqTexts = {
  title: 'Frequently Asked Questions',
  activeTabs: ['For Homeowners', 'For Residents'],
  collapsable: {
    homeowner: [
      {
        q: FAQS_STRINGS.homeowner[4].q,
        a: FAQS_STRINGS.homeowner[4].a,
      },
      {
        q: FAQS_STRINGS.homeowner[6].q,
        a: FAQS_STRINGS.homeowner[6].a,
      },
      {
        q: FAQS_STRINGS.homeowner[9].q,
        a: FAQS_STRINGS.homeowner[9].a,
      },
      {
        q: FAQS_STRINGS.homeowner[8].q,
        a: FAQS_STRINGS.homeowner[8].a,
      },
      {
        q: FAQS_STRINGS.homeowner[2].q,
        a: FAQS_STRINGS.homeowner[2].a,
      },
    ],
    resident: [
      {
        q: FAQS_STRINGS.resident[0].q,
        a: FAQS_STRINGS.resident[0].a,
      },
      {
        q: FAQS_STRINGS.resident[1].q,
        a: FAQS_STRINGS.resident[1].a,
      },
      {
        q: FAQS_STRINGS.resident[2].q,
        a: FAQS_STRINGS.resident[2].a,
      },
      {
        q: FAQS_STRINGS.resident[3].q,
        a: FAQS_STRINGS.resident[3].a,
      },
      {
        q: FAQS_STRINGS.resident[5].q,
        a: FAQS_STRINGS.resident[5].a,
      },
    ],
  },
};

const faqStructuredData = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: [...faqTexts.collapsable.homeowner, ...faqTexts.collapsable.resident].map((faq) => {
    return {
      '@type': 'Question',
      name: faq.q,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.a.join('\n'),
      },
    };
  }),
};

type Faq = {
  q: string;
  a: string[];
};

export const FaqSection = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(-1);

  const createAccordionItems = (faqs: Faq[]) => {
    return faqs.map((faq, i) => ({
      title: <div id={`question-${i + 1}`}>{faq.q}</div>,
      content: <StringComponent string={faq.a} />,
    }));
  };

  const homeownerAccordionItems = createAccordionItems(faqTexts.collapsable.homeowner);
  const residentAccordionItems = createAccordionItems(faqTexts.collapsable.resident);

  const itemsToAccordion = [homeownerAccordionItems, residentAccordionItems];

  const handleClickAccordion =
    (currVal: number, updateFunc: React.Dispatch<React.SetStateAction<number>>) => (selectedOption: number) => {
      const nextSelectedOption = selectedOption === currVal ? -1 : selectedOption;
      updateFunc(nextSelectedOption);
    };

  return (
    <>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(faqStructuredData) }} />
      </Head>

      <Text className="text-center mb-xl" variant="h2" fontWeight="semibold">
        {faqTexts.title}
      </Text>

      <Tabs defaultIndex={0} isDraggable>
        <Tabs.TabList className="mb-xl text-center block">
          {faqTexts.activeTabs.map((state, i) => (
            <Tabs.TabItem className="border-2" key={i} index={i}>
              {state}
            </Tabs.TabItem>
          ))}
        </Tabs.TabList>
        <Tabs.PanelList>
          {itemsToAccordion.map((items, i) => (
            <Tabs.PanelItem key={`accordion-${i + 1}`}>
              <Accordion
                items={items}
                index={selectedQuestion[i]}
                onChange={handleClickAccordion(selectedQuestion, setSelectedQuestion)}
              ></Accordion>
            </Tabs.PanelItem>
          ))}
        </Tabs.PanelList>
      </Tabs>
    </>
  );
};
