import { BASE_PATHS } from '@belong/common';
import clsx from 'clsx';
import { HOME_PAGE_STRINGS } from './home-page.strings';
import styles from './home.module.css';

export const HOME_CTA_PROPS = [
  {
    title: HOME_PAGE_STRINGS['cta1.title'],
    body: HOME_PAGE_STRINGS['cta1.body'],
    ctaLabel: HOME_PAGE_STRINGS['cta1.ctaLabel'],
    bodyClassName: styles.cta1Body,
    ctaHref: BASE_PATHS.HOMEOWNER,
    src: '/home-page/home_landing_ho_img_2x.png',
    imgContainerClassName: clsx(styles.cta1ImgContainer, 'md:mr-3xl'),
    imgClassName: 'aspect-w-15 aspect-h-11',
  },
  {
    title: HOME_PAGE_STRINGS['cta2.title'],
    body: HOME_PAGE_STRINGS['cta2.body'],
    ctaLabel: HOME_PAGE_STRINGS['cta2.ctaLabel'],
    bodyClassName: styles.cta2Body,
    ctaHref: BASE_PATHS.HOME,
    src: '/home-page/home_landing_re_img_2x.png',
    imgContainerClassName: clsx(styles.cta2ImgContainer, 'md:ml-xl'),
    imgClassName: 'aspect-w-15 aspect-h-16',
    isInverted: true,
  },
  {
    title: HOME_PAGE_STRINGS['cta.manage.title'],
    body: HOME_PAGE_STRINGS['cta.manage.subtitle'],
    bodyClassName: styles.cta1Body,
    ctaHref: BASE_PATHS.HOMEOWNER,
    src: '/home-page/re_lp_mobile_app.png',
    imgContainerClassName: clsx(styles.cta1ImgContainer, 'md:mr-3xl'),
    imgClassName: 'aspect-w-15 aspect-h-11',
  },
];

export const CITY_AND_NH_LINKS = [
  { href: '/homeowners/torrance-ca-property-management', name: 'Torrance', type: 'City' },
  { href: '/homeowners/seattle-wa-property-management/ballard', name: 'Ballard', type: 'Neighborhood' },
  { href: '/homeowners/livermore-ca-property-management', name: 'Livermore', type: 'City' },
  { href: '/homeowners/dublin-ca-property-management', name: 'Dublin', type: 'City' },
  { href: '/homeowners/los-angeles-ca-property-management/van-nuys', name: 'Van Nuys', type: 'Neighborhood' },
  { href: '/homeowners/bonsall-ca-property-management', name: 'Bonsall', type: 'City' },
  { href: '/homeowners/key-biscayne-fl-property-management', name: 'Key Biscayne', type: 'City' },
  { href: '/homeowners/leavenworth-wa-property-management', name: 'Leavenworth', type: 'City' },
  { href: '/homeowners/margate-fl-property-management', name: 'Margate', type: 'City' },
  { href: '/homeowners/hacienda-heights-ca-property-management', name: 'Haceinda Heights', type: 'City' },
  { href: '/homeowners/seattle-wa-property-management/montlake', name: 'Montlake', type: 'Neighborhood' },
  { href: '/homeowners/newcastle-wa-property-management', name: 'New Castle ', type: 'City' },
  { href: '/homeowners/seattle-wa-property-management/wallingford', name: 'Wallingford', type: 'Neighborhood' },
  { href: '/homeowners/lighthouse-point-fl-property-management', name: 'Lighthouse Point ', type: 'City' },
  { href: '/homeowners/seattle-wa-property-management/phinney-ridge', name: 'Phinney Ridge', type: 'Neighborhood' },
  { href: '/homeowners/tukwila-wa-property-management', name: 'Tukwila', type: 'City' },
  { href: '/homeowners/hallandale-beach-fl-property-management', name: 'Hallandale Beach ', type: 'City' },
  { href: '/homeowners/north-richmond-ca-property-management', name: 'North Richmond', type: 'City' },
  { href: '/homeowners/granite-falls-wa-property-management', name: 'Granite Falls ', type: 'City' },
  { href: '/homeowners/shadow-lake-wa-property-management', name: 'Shadow Lake ', type: 'City' },
  { href: '/homeowners/snoqualmie-pass-wa-property-management', name: 'Snoqualmie Pass ', type: 'City' },
  { href: '/homeowners/gold-bar-wa-property-management', name: 'Gold Bar ', type: 'City' },
  { href: '/homeowners/lake-cassidy-wa-property-management', name: 'Lake Cassidy', type: 'City' },
  { href: '/homeowners/lochsloy-wa-property-management', name: 'Lochsloy', type: 'City' },
];
