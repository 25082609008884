import { NextSeo, NextSeoProps } from 'next-seo';
import { useRouter } from 'next/router';

type SeoProps = NextSeoProps & {
  additionalCanonicalPath?: string;
};

export function MetaSEO(props: SeoProps) {
  const { additionalCanonicalPath = '' } = props;
  const { asPath } = useRouter();
  const url = new URL(asPath, 'https://belonghome.com');
  const adaptedUrl = `${url.origin}${url.pathname}${additionalCanonicalPath}`;
  const adaptedProps = {
    ...props,
    canonical: props.canonical ? props.canonical : adaptedUrl,
  };

  return <NextSeo {...adaptedProps} />;
}
