// PLEASE READ DOCS BEFORE USING OR EDITING THIS COMPONENT

/* eslint-disable react/no-danger */
import React from 'react';
import clsx from 'clsx';
import styles from './string.module.css';

type StringComponentProps = {
  string: any;
  as?: string | null;
  className?: string | null;
};

export function StringComponent({ string, as = null, className = null }: StringComponentProps): JSX.Element {
  const parseLinks = (text: string): string => {
    if (!text?.indexOf) {
      return '';
    }

    const linkStringStart = text?.indexOf('[');

    if (linkStringStart < 0) {
      return text;
    }

    const linkStringEnd = text.indexOf(']');
    const linkText = text.substring(linkStringStart + 1, linkStringEnd);
    const linkTagStart = linkStringEnd + 2;
    const linkTagEnd = text.indexOf(')', linkStringStart);
    const linkTag = text.substring(linkTagStart, linkTagEnd);
    const formatString = `${text.substring(0, linkStringStart)}<a href="${linkTag}">${linkText}</a>
         ${parseLinks(text.substring(linkTagEnd + 1))}`;
    return formatString;
  };

  const createMarkup = () => {
    if (Array.isArray(string)) {
      const concatedString = string.map((s) => `<div class="mb-xs">${parseLinks(s)}</div>`);
      return { __html: concatedString.join('') };
    }

    return { __html: parseLinks(string) };
  };

  const Tag = `${as || 'div'}`;
  return <Tag className={clsx(styles.string, className)} dangerouslySetInnerHTML={createMarkup()} />;
}
